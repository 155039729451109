import React, { useEffect, useState, useRef } from 'react'

import { api, apiPost } from '../functions/api.js'
import { useCookies } from 'react-cookie'
import '../styles/CarnetDeLiaisonLecture.scss'
import Titre from '../components/Titre.jsx'
import NoteCarnetDeLiaison from '../components/NoteCarnetDeLiaison.jsx'
import { Button, Flex, Select, Space, Typography } from 'antd'

function CarnetLiaisonLecture() {
  const [notes, setNotes] = useState([])
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [jeunes, setJeunes] = useState([])
  const [jeunesHidden, setJeunesHidden] = useState([])
  const [cours, setCours] = useState([[], [], []])
  const [profs, setProfs] = useState([])
  const [filterProf, setFilterProf] = useState(null)
  const [filterJeune, setFilterJeune] = useState(null)
  const [filterCours, setFilterCours] = useState(null)
  const lastNoteRef = useRef(null)
  const [allNotesLoaded, setAllNotesLoaded] = useState(false) // État pour vérifier si toutes les notes sont chargées
  const [cookies, setCookie, removeCookie] = useCookies(['id', 'token'])

  useEffect(() => {
    fetchJeunes()
    fetchCours()
    fetchProfs()
  }, [])

  useEffect(() => {
    if (!allNotesLoaded) {
      const observer = new IntersectionObserver(handleIntersect, {
        root: null,
        rootMargin: '0px',
        threshold: 1,
      })

      if (lastNoteRef.current) observer.observe(lastNoteRef.current)

      return () => {
        observer.disconnect()
      }
    }
  }, [page, allNotesLoaded])

  // Fonction pour récupérer les notes de la page spécifiée

  const fetchNotes = async (page) => {
    if (loading) return
    setLoading(true)

    try {
      const response = await apiPost(
        `carnetliaison/all/20/${page}`,
        {
          id: cookies['id'],
          token: cookies['token'],
        },
        { cours: filterCours, prof: filterProf, jeune: filterJeune }
      )

      if (response.data.length === 0) {
        setAllNotesLoaded(true) // Toutes les notes sont chargées
      } else {
        setNotes((prevNotes) => [...prevNotes, ...response.data])
        setPage(page + 1)
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des données:', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchJeunes = async () => {
    try {
      const response = await api(`jeune/allSort/prof/` + cookies['id'], {
        id: cookies['id'],
        token: cookies['token'],
      })

      setJeunes([...response.data.items, ...response.data.hiddenItems, ...response.data.sorts])
    } catch (error) {
      console.error('Erreur lors de la récupération des jeunes:', error)
    }
  }
  const fetchCours = async () => {
    try {
      const response = await api(`cours/allSort/prof/` + cookies['id'], {
        id: cookies['id'],
        token: cookies['token'],
      })

      setCours(response.data.items)
    } catch (error) {
      console.error('Erreur lors de la récupération des cours:', error)
    }
  }
  const fetchProfs = async () => {
    try {
      const response = await api(`prof/allSort/` + cookies['id'], {
        id: cookies['id'],
        token: cookies['token'],
      })
      setProfs(response.data)
    } catch (error) {
      console.error('Erreur lors de la récupération des profs:', error)
    }
  }

  // Fonction pour gérer l'intersection de la dernière note avec la fenêtre visible
  const handleIntersect = (entries) => {
    if (entries[0].isIntersecting && !loading && !allNotesLoaded) {
      fetchNotes(page)
    }
  }
  const reinitFilter = () => {
    setFilterCours(null)
    setFilterJeune(null)
    setFilterProf(null)
  }

  return (
    <div className="notesCarnetLiaison">
      <Titre>Lecture des notes</Titre>
      <Titre>
        <Flex vertical>
          <h3>Filtrer les notes</h3>
          <Flex justify="space-between" style={{ width: '100%', marginTop: 15 }}>
            <Select
              style={{ width: '25%' }}
              placeholder="choisir un jeune"
              options={jeunes}
              onChange={(e) => setFilterJeune(e)}
              value={filterJeune}
            />
            <Select
              style={{ width: '25%' }}
              placeholder="choisir un prof"
              options={profs}
              onChange={(e) => setFilterProf(e)}
              value={filterProf}
            />
            <Select
              style={{ width: '25%' }}
              placeholder="choisir un cours"
              options={[...cours[0], ...cours[1], ...cours[2]]}
              onChange={(e) => setFilterCours(e)}
              value={filterCours}
            />
            <Button onClick={reinitFilter}>Réinitialiser</Button>
            <Button
              type="primary"
              onClick={() => {
                setPage(1)
                setNotes([])
                fetchNotes(1)
              }}
            >
              Filtrer
            </Button>
          </Flex>
        </Flex>
      </Titre>
      {notes.map((note) => (
        <NoteCarnetDeLiaison
          key={note.id}
          note={note}
          jeunes={jeunes}
          jeunesHidden={jeunesHidden}
          cours={[...cours[0], ...cours[1], ...cours[2]]}
          profs={profs}
        />
      ))}
      {!allNotesLoaded && ( // Afficher noteSeeMore uniquement si toutes les notes ne sont pas chargées
        <div className="noteSeeMore" ref={lastNoteRef}>
          <div className="bookshelf_wrapper">
            <ul className="books_list">
              <li className="book_item first"></li>
              <li className="book_item second"></li>
              <li className="book_item third"></li>
              <li className="book_item fourth"></li>
              <li className="book_item fifth"></li>
              <li className="book_item sixth"></li>
            </ul>
            <div className="shelf"></div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CarnetLiaisonLecture
