import React, { useEffect, useState } from 'react'
import { api, apiPost } from '../functions/api'
import { useCookies } from 'react-cookie'
import { Button, Flex, Input, Modal, Select, Space, Spin, Table, Typography } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'

const ParametresCours = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['id', 'token', 'cours_fav'])
  const [cours, setCours] = useState(null)
  const [coursEdit, setCoursEdit] = useState({})
  const [loading, setLoading] = useState(true)
  const [openModalCours, setOpenModalCours] = useState(false)
  const groupes = [
    { label: 'Cours', value: 1 },
    { label: 'Titulariat', value: 2 },
    { label: 'PMS', value: 5 },
    { label: 'Activités extrascolaires', value: 6 },
    { label: 'Educateur', value: 3 },
    { label: "Statistiques d'absences", value: 4 },
    { label: 'autres', value: 0 },
    { label: 'Informations et transmissions', value: 7 },
  ]
  const columns = [
    {
      title: 'Intitulé',
      dataIndex: 'intitule',
      key: 'intitule',
    },
    {
      title: 'Groupe',
      dataIndex: 'groupe',
      key: 'groupe',
      render: (_, record) => {
        //console.log(record)
        const leGroupe = groupes.find((c) => c.value === record.groupe)
        //console.log(leGroupe)
        return leGroupe.label
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => editCours(record)} icon={<EditOutlined />} />
          <Button onClick={() => deleteCours(record.id)} icon={<DeleteOutlined />} />
        </Space>
      ),
    },
  ]
  const fetchCours = async () => {
    try {
      const response = await api(`cours/all`, {
        id: cookies['id'],
        token: cookies['token'],
      })
      setCours(response.data)
      setLoading(false)
    } catch (error) {
      console.error('Erreur lors de la récupération des absences groupes:', error)
    }
  }
  const saveCours = async () => {
    try {
      await toast.promise(
        apiPost(
          coursEdit.id === 'new' ? 'cours/create' : `cours/update/${coursEdit.id}`,
          {
            id: cookies['id'],
            token: cookies['token'],
          },
          coursEdit
        ),
        {
          pending: 'Envoi des données en cours...', // Message pendant le chargement
          success: 'Données sauvegardées avec succès 👌', // Message de succès
          error: {
            render({ data }) {
              // Retourne un message d'erreur basé sur la réponse de l'API
              return `Erreur lors de la sauvegarde : ${data?.response?.data?.message || 'Veuillez réessayer.'}`
            },
          }, // Message d'erreur
        }
      )

      // Mets à jour la référence initiale après la sauvegarde
    } catch (error) {
      console.error("Erreur lors de l'envoi de la date: ", error)
    } finally {
      setOpenModalCours(false)
      fetchCours()
    }
  }
  const editCours = (cours) => {
    setCoursEdit(cours)
    setOpenModalCours(true)
  }
  const deleteCours = async (coursId) => {
    try {
      await toast.promise(
        // La promesse envoyée à l'API
        api(`cours/destroy/${coursId}`, {
          id: cookies['id'],
          token: cookies['token'],
        }),
        {
          pending: 'Suppression en cours...', // Message pendant le chargement
          success: 'Données supprimées avec succès 👌', // Message de succès
          error: {
            render({ data }) {
              // Retourne un message d'erreur basé sur la réponse de l'API
              return `Erreur lors de la suppression : ${data?.response?.data?.message || 'Veuillez réessayer.'}`
            },
          }, // Message d'erreur
        }
      )
    } catch (error) {
      console.error('Erreur lors de la suppression de la date: ', error)
    } finally {
      fetchCours()
    }
  }
  useEffect(() => {
    fetchCours()
  }, [])
  return !loading ? (
    <Flex vertical>
      <Typography.Title
        style={{
          color: '#6a778e',
        }}
        level={3}
      >
        Les cours & les contextes
      </Typography.Title>
      <Table columns={columns} dataSource={cours} pagination={false} />

      <Modal
        open={openModalCours}
        destroyOnClose={true}
        onOk={saveCours}
        onCancel={() => setOpenModalCours(false)}
        width={600}
      >
        <Typography.Title
          style={{
            color: '#6a778e',
            width: '100%',
          }}
          level={3}
        >
          Ajouter un groupe d'absences
        </Typography.Title>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography.Title
            style={{
              color: '#6a778e',
            }}
            level={5}
          >
            L'intitulé du groupe
          </Typography.Title>
          <Input
            value={coursEdit.intitule ? coursEdit.intitule : null}
            placeholder="Indique l'initulé du cours ou du contexte"
            style={{ width: '100%' }}
            onChange={(e) =>
              setCoursEdit({
                ...coursEdit,
                intitule: e.target.value,
              })
            }
          />
        </Space>
        <Space direction="vertical" style={{ width: '100%', marginTop: 15 }}>
          <Typography.Title
            style={{
              color: '#6a778e',
            }}
            level={5}
          >
            La couleur du groupe
          </Typography.Title>
          <Select
            options={groupes}
            style={{ width: '100%' }}
            placeholder="Selectionne une couleur"
            value={coursEdit.groupe ? coursEdit.groupe : null}
            onChange={(e) =>
              setCoursEdit({
                ...coursEdit,
                groupe: e,
              })
            }
          />
        </Space>
      </Modal>
    </Flex>
  ) : (
    <Spin />
  )
}

export default ParametresCours
