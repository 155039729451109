import React, { useEffect, useRef, useState } from 'react'
import { formaterDate } from '../functions/dateUtils.js'
import { api, apiPut } from '../functions/api.js'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import 'ckeditor5/ckeditor5.css'
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  List,
  Underline,
  Strikethrough,
  Highlight,
  Alignment,
  Link,
  Font,
} from 'ckeditor5'
import 'ckeditor5/ckeditor5.css'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { DatePicker, Form, Input, Popconfirm, Select } from 'antd'
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import { useCookies } from 'react-cookie'
import { useQuill } from 'react-quilljs'
import 'quill/dist/quill.snow.css'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import locale from 'antd/locale/fr_BE'
import 'dayjs/locale/fr.js'
dayjs.locale('fr_BE')
const dateFormat = 'DD/MM/YYYY'
const NoteCarnetDeLiaison = ({ note, jeunes, cours, profs }) => {
  const divRef = useRef(null)
  const [deleteNote, setDeleteNote] = useState(false)
  const [edit, setEdit] = useState(false)
  const [noteId, setNoteId] = useState(note.id)
  const [commentaire, setCommentaire] = useState(note.commentaire)
  const [intitule, setIntitule] = useState(note.intitule)
  //const [eleves, setEleves] = useState(jeunes)
  //const [lesCours, setCours] = useState(cours)
  const [elevesId, setElevesId] = useState(JSON.parse(note.eleves_id))
  const [coursId, setCoursId] = useState(JSON.parse(note.cours_id))
  const [date, setDate] = useState(note.date)
  const [profId, setProfId] = useState(note.prof_id)
  const formDataInitial = {
    id: noteId,
    date: date,
    prof: profId,
    jeunes: JSON.stringify(elevesId),
    contexte: JSON.stringify(coursId),
    intitule: intitule,
    commentaire: commentaire,
  }
  const [formData, setFormData] = useState(formDataInitial)
  const [cookies, setCookie, removeCookie] = useCookies(['id', 'token', 'cours_fav'])
  const findEleveById = (id) => {
    const foundEleve = jeunes.find((eleve) => eleve.value === parseInt(id, 10))
    return foundEleve ? foundEleve.label : null
  }
  const findCoursById = (id) => {
    const foundCours = cours.find((c) => c.value === parseInt(id, 10))
    return foundCours ? foundCours.label : null
  }
  const findProfById = (id) => {
    const foundProf = profs.find((prof) => prof.value === parseInt(id, 10))
    return foundProf ? foundProf.label : null
  }
  const generatePdf = () => {
    let nomPdf = 'note_de_' + findEleveById(elevesId[0]) + '_du_' + date
    nomPdf = nomPdf.replace(/ /g, '_')
    html2canvas(divRef.current)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF()
        const imgWidth = pdf.internal.pageSize.getWidth()
        const imgHeight = (canvas.height * imgWidth) / canvas.width
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight)
        pdf.save(nomPdf + '.pdf')
      })
      .catch((error) => {
        console.error('Error generating PDF', error)
      })
  }
  const sendDatasToApi = async () => {
    try {
      const response = await apiPut(
        'carnetliaison/update',
        {
          id: cookies['id'],
          token: cookies['token'],
          'Content-Type': 'application/json',
        },
        formData
      )
      if (response.status === 200) {
        toast.success(response.data.message)
        setCommentaire(formData.commentaire)
        setIntitule(formData.intitule)
        setElevesId(JSON.parse(formData.jeunes))
        setProfId(formData.prof)
        setCoursId(JSON.parse(formData.contexte))
        setDate(formData.date)
      } else {
        console.error('Erreur:', response.data.message)
        toast.error(response.data.message)
      }
    } catch (error) {
      console.error('Failed to submit form:', error)
    }
  }
  const supprimerNote = async () => {
    try {
      const response = await api(`carnetliaison/destroy/${noteId}`, {
        id: cookies['id'],
        token: cookies['token'],
        'Content-Type': 'application/json',
      })
      if (response.status === 200) {
        toast.success(response.data.message)
        setDeleteNote(true)
      } else {
        console.error('Erreur:', response.data.message)
        toast.error(response.data.message)
      }
    } catch (error) {
      console.error('Failed to submit form:', error)
    }
  }

  return !deleteNote ? (
    !edit ? (
      <div className="noteCarnetLiaison" key={note.id} ref={divRef}>
        <div className="noteCarnetLiaison__header">
          <div className="noteCarnetLiaison__header__top">
            {elevesId.map((id, index) => {
              const item = findEleveById(id)
              return item ? (index > 0 ? ', ' : '') + item : null
            })}
          </div>
          <div className="noteCarnetLiaison__header__center">
            {coursId.map((id, index) => {
              const item = findCoursById(id)
              return item ? (index > 0 ? ', ' : '') + item : null
            })}
          </div>
          <div className="noteCarnetLiaison__header__bottom">par {findProfById(profId)}</div>
        </div>
        <div className="noteCarnetLiaison__body">
          {<div className="noteCarnetLiaison__body__header">{intitule}</div>}
          {<div className="noteCarnetLiaison__body__body" dangerouslySetInnerHTML={{ __html: commentaire }}></div>}
          <div className="noteCarnetLiaison__body__footer"></div>
        </div>
        <div className="noteCarnetLiaison__footer">
          <div className="noteCarnetLiaison__footer__left">{formaterDate(date, 'mots')}</div>
          <div className="noteCarnetLiaison__footer__right">
            {
              <>
                <FilePdfOutlined onClick={generatePdf} />
                <EditOutlined
                  onClick={() => {
                    setEdit(!edit)
                  }}
                />
                <Popconfirm
                  title="Suppression de la note"
                  description="Es-tu sûr de vouloir supprimer la note ?"
                  onConfirm={supprimerNote}
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: 'red',
                      }}
                    />
                  }
                  okText="Oui"
                  cancelText="Non"
                >
                  <DeleteOutlined />
                </Popconfirm>
              </>
            }
          </div>
        </div>
      </div>
    ) : (
      <div className="noteCarnetLiaison" key={note.id} ref={divRef}>
        <div className="noteCarnetLiaison__header">
          <div className="noteCarnetLiaison__header__top">
            <Select
              addonBefore="jeunes"
              mode="multiple"
              style={{ width: '100%' }}
              size="large"
              defaultValue={elevesId}
              options={jeunes}
              multiple
              onChange={(e) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  jeunes: JSON.stringify(e),
                }))
              }}
            />
          </div>
          <div className="noteCarnetLiaison__header__center">
            <Select
              style={{ width: '100%', fontSize: '1em', marginTop: 12 }}
              size="large"
              defaultValue={coursId}
              options={cours}
              onChange={(e) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  contexte: JSON.stringify([e]),
                }))
              }}
            />
          </div>
          <div className="noteCarnetLiaison__header__bottom">
            <Select
              style={{ width: '100%', fontSize: '1em', marginTop: 12 }}
              size="large"
              defaultValue={profId}
              options={profs}
              onChange={(e) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  prof: e,
                }))
              }}
            />
          </div>
        </div>
        <div className="noteCarnetLiaison__body">
          <Input
            addonBefore="contexte"
            defaultValue={intitule}
            size="large"
            style={{ width: '100%' }}
            onChange={(e) => {
              setFormData((prevFormData) => ({
                ...prevFormData,
                intitule: e.target.value,
              }))
            }}
          />

          <div className="commentaire__box" style={{ marginTop: 12 }}>
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: {
                  items: [
                    'heading', // Titres
                    'fontSize', // Taille de texte
                    'bold', // Gras
                    'italic', // Italique
                    'underline', // Souligné
                    'strikethrough', // Barré
                    'highlight', // Surligné
                    '|',
                    'bulletedList', // Liste non ordonnée (ul)
                    'numberedList', // Liste ordonnée (ol)
                    '|',
                    'alignment', // Alignement de texte
                    'link', // Liens
                    'imageUpload', // Image
                    'undo', // Annuler
                    'redo', // Répéter
                  ],
                },
                plugins: [
                  Bold,
                  Italic,
                  Underline,
                  Strikethrough,
                  Font,
                  Highlight,
                  Paragraph,
                  List,
                  Alignment,
                  Link,
                  Image,
                ],
                fontSize: {
                  options: ['tiny', 'small', 'default', 'big', 'huge'],
                },
                alignment: {
                  options: ['left', 'center', 'right', 'justify'],
                },
                highlight: {
                  options: [
                    {
                      model: 'yellowMarker',
                      class: 'marker-yellow',
                      title: 'Yellow marker',
                      color: 'var(--ck-highlight-marker-yellow)',
                      type: 'marker',
                    },
                    {
                      model: 'greenMarker',
                      class: 'marker-green',
                      title: 'Green marker',
                      color: 'var(--ck-highlight-marker-green)',
                      type: 'marker',
                    },
                    {
                      model: 'pinkMarker',
                      class: 'marker-pink',
                      title: 'Pink marker',
                      color: 'var(--ck-highlight-marker-pink)',
                      type: 'marker',
                    },
                  ],
                },
                initialData: commentaire,
              }}
              onChange={(e, editor) => {
                const commentaire = editor.data.get()

                setFormData((prevFormData) => ({
                  ...prevFormData,
                  commentaire: commentaire,
                }))
              }}
            />
          </div>
          <div className="noteCarnetLiaison__body__footer"></div>
        </div>
        <div className="noteCarnetLiaison__footer">
          <div className="noteCarnetLiaison__footer__left">
            <DatePicker
              style={{ width: '100%' }}
              size="large"
              defaultValue={dayjs(date)}
              format={dateFormat}
              onChange={(e) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  date: dayjs(e).format('YYYY-MM-DD HH:mm:ss'),
                }))
              }}
            />
          </div>
          <div className="noteCarnetLiaison__footer__right">
            <i className="fa-solid fa-xmark"></i>
            <CloseOutlined
              onClick={() => {
                setEdit(!edit)
                setFormData(formDataInitial)
              }}
            />
            <SaveOutlined
              onClick={() => {
                sendDatasToApi()
                setEdit(!edit)
              }}
            />
          </div>
        </div>
      </div>
    )
  ) : null
}

export default NoteCarnetDeLiaison
