import React, { useState } from 'react'
import { Button, Drawer, Modal, Switch, message } from 'antd'
import { toast } from 'react-toastify'
import { CopyOutlined } from '@ant-design/icons'

const ModalCopyEmails = ({ open, setOpen, jeunesActifs, jeunesArchives, jeunesAttentes }) => {
  const [mailsSelectionnes, setMailsSelectionnes] = useState([])

  const emailsJeunesActifs = jeunesActifs.map((jeune) => jeune.email).filter((email) => email && email.trim() !== '')

  const emailsTuteursActifs = jeunesActifs.flatMap((jeune) =>
    jeune.tuteur
      ? JSON.parse(jeune.tuteur)
          .map((tut) => tut.email)
          .filter((email) => email && email.trim() !== '')
      : []
  )

  const emailsJeunesAttentes = jeunesAttentes
    .map((jeune) => jeune.email)
    .filter((email) => email && email.trim() !== '')

  const emailsTuteursAttentes = jeunesAttentes.flatMap((jeune) =>
    jeune.tuteur
      ? JSON.parse(jeune.tuteur)
          .map((tut) => tut.email)
          .filter((email) => email && email.trim() !== '')
      : []
  )

  const emailsJeunesArchives = jeunesArchives
    .map((jeune) => jeune.email)
    .filter((email) => email && email.trim() !== '')

  const emailsTuteursArchives = jeunesArchives.flatMap((jeune) =>
    jeune.tuteur
      ? JSON.parse(jeune.tuteur)
          .map((tut) => tut.email)
          .filter((email) => email && email.trim() !== '')
      : []
  )

  // Ajout/Retrait d'un groupe d'emails
  const toggleGroup = (emails, add) => {
    setMailsSelectionnes((prev) => {
      const newSelection = new Set(prev)
      emails.forEach((email) => {
        if (add) {
          newSelection.add(email)
        } else {
          newSelection.delete(email)
        }
      })
      return Array.from(newSelection)
    })
  }

  // Copier les emails sélectionnés
  const copyMails = () => {
    toast.dismiss()
    if (mailsSelectionnes.length === 0) {
      toast.warning('Aucune adresse email sélectionnée.')
      return
    }
    const emails = mailsSelectionnes.join(', ')
    navigator.clipboard.writeText(emails)
    toast.success('Adresses emails copiées dans le presse-papier.')
  }

  // Vérifier si une checkbox est cochée
  const isChecked = (email) => mailsSelectionnes.includes(email)

  // Ajouter/Retirer un email individuel
  const toggleEmail = (email) => {
    setMailsSelectionnes((prev) => (prev.includes(email) ? prev.filter((e) => e !== email) : [...prev, email]))
  }

  const renderJeunes = (jeunes, groupName) => (
    <div>
      <h3 style={{ marginBottom: 10, marginTop: 10 }}>{groupName}</h3>
      {jeunes.map((jeune) => {
        // Vérification des emails des jeunes
        if (!jeune.email || jeune.email.trim() === '') {
          return null
        }

        // Vérification des emails des tuteurs
        const tuteurs = jeune.tuteur
          ? JSON.parse(jeune.tuteur).filter((tut) => tut.email && tut.email.trim() !== '')
          : []

        return (
          <div key={jeune.id} style={{ display: 'flex', marginBottom: '8px', gap: '20px' }}>
            <div>
              <label style={{ cursor: 'pointer' }}>
                <input
                  style={{ marginRight: 5 }}
                  type="checkbox"
                  onChange={() => toggleEmail(jeune.email)}
                  checked={isChecked(jeune.email)}
                />
                {`${jeune.nom} ${jeune.prenom}`}
              </label>
            </div>
            {tuteurs.map((tut) => (
              <div key={tut.id}>
                <label style={{ cursor: 'pointer' }}>
                  <input
                    style={{ marginRight: 5 }}
                    type="checkbox"
                    onChange={() => toggleEmail(tut.email)}
                    checked={isChecked(tut.email)}
                  />
                  {`${tut.nom} ${tut.prenom} (${tut.role})`}
                </label>
              </div>
            ))}
          </div>
        )
      })}
    </div>
  )

  return (
    <Drawer
      open={open}
      destroyOnClose={true}
      onClose={() => setOpen(false)}
      title="Copier des adresses emails"
      width={'100%'}
      footer={[
        <Button key="submit" type="primary" onClick={copyMails} icon={<CopyOutlined />}>
          Copier les adresses emails
        </Button>,
      ]}
    >
      <div style={{ marginBottom: '16px', display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
        <Switch
          checkedChildren="Jeunes Actifs"
          unCheckedChildren="Jeunes Actifs"
          onClick={(checked) => toggleGroup(emailsJeunesActifs, checked)}
        />
        <Switch
          checkedChildren="Tuteurs Actifs"
          unCheckedChildren="Tuteurs Actifs"
          onClick={(checked) => toggleGroup(emailsTuteursActifs, checked)}
        />
        <Switch
          checkedChildren="Jeunes en attentes"
          unCheckedChildren="Jeunes en attentes"
          onClick={(checked) => toggleGroup(emailsJeunesAttentes, checked)}
        />
        <Switch
          checkedChildren="Tuteurs en attentes"
          unCheckedChildren="Tuteurs en attentes"
          onClick={(checked) => toggleGroup(emailsTuteursAttentes, checked)}
        />
        <Switch
          checkedChildren="Jeunes archivés"
          unCheckedChildren="Jeunes archivés"
          onClick={(checked) => toggleGroup(emailsJeunesArchives, checked)}
        />
        <Switch
          checkedChildren="Tuteurs archivés"
          unCheckedChildren="Tuteurs archivés"
          onClick={(checked) => toggleGroup(emailsTuteursArchives, checked)}
        />
      </div>
      {renderJeunes(jeunesActifs, 'Jeunes Actifs')}
      {renderJeunes(jeunesAttentes, 'Jeunes Attentes')}
      {renderJeunes(jeunesArchives, 'Jeunes Archives')}
    </Drawer>
  )
}

export default ModalCopyEmails
