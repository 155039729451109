import {
  ApiFilled,
  BarChartOutlined,
  DeleteOutlined,
  EditOutlined,
  LineChartOutlined,
  PieChartOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import {
  Button,
  Card,
  Divider,
  Drawer,
  Flex,
  Image,
  Popconfirm,
  Segmented,
  Select,
  Splitter,
  Table,
  Tooltip,
} from 'antd'
import Title from 'antd/es/skeleton/Title'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import BilanNotesEdit from './BilanNotesEdit'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { api, apiPost } from '../functions/api'

import 'ckeditor5/ckeditor5.css'
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  List,
  Underline,
  Strikethrough,
  Highlight,
  Alignment,
  Link,
  Font,
} from 'ckeditor5'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import { debounce } from 'lodash'
import html2canvas from 'html2canvas'
import { Bar, BarChart, Cell, Legend, Line, LineChart, Pie, PieChart, XAxis, YAxis } from 'recharts'

const BilanVolet = ({
  charts,
  volet,
  prof,
  bilanId,
  cours,
  coursModalCommentaire,
  setCoursModalCommentaire,
  fullScreen,
  handleModalCommentaire,
  bilanProf,
  profs,
  profModalCommentaire,
  setProfModalCommentaire,
  notes,
  debouncedSaveBilan,
  fetchDatas,
  sendImageToApi,
  deleteChart,
  absencesDatas,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['id', 'token', 'cours_fav'])
  const [modalNotes, setModalNotes] = useState(volet.notes)
  const [tempNotesToBilan, setTempNotesToBilan] = useState([])
  const [openModalCommentaire, setOpenModalCommentaire] = useState(false)
  const [nouveauCommentaire, setNouveauCommentaire] = useState({ prof: prof.value, volets: [volet] })
  const [notesFiltrees, setNotesFiltrees] = useState([])
  const [isAbsences, setIsAbsences] = useState(false)
  const [chartSelected, setChartSelected] = useState(0)

  const editorRef = useRef(null)
  const pieChartRef1 = useRef(null)
  const pieChartRef2 = useRef(null)
  const barChartRef = useRef(null)
  const lineChartRef = useRef(null)

  const notesColumns = [
    { title: '#', dataIndex: 'id', key: 'id' },
    { title: 'Date', dataIndex: 'date', key: 'date', render: (date) => dayjs(date).format('DD/MM/YYYY') },
    {
      title: 'Commentaire',
      dataIndex: 'commentaire',
      key: 'commentaire',
      render: (html) => (typeof html === 'string' ? <div dangerouslySetInnerHTML={{ __html: html }}></div> : html),
    },
  ]
  const addCommentsNotesColumns = [
    { title: '#', dataIndex: 'id', key: 'id' },
    { title: 'Date', dataIndex: 'date', key: 'date', render: (date) => dayjs(date).format('DD/MM/YYYY') },
    {
      title: 'Contexte',
      dataIndex: 'cours_id',
      key: 'cours',
      render: (_, record) => {
        const leCours = cours.find((c) => record.cours_id.includes(c.value))
        return leCours ? leCours.label : null
      },
    },
    {
      title: 'Commentaire',
      dataIndex: 'commentaire',
      key: 'commentaire',
      render: (html) => (typeof html === 'string' ? <div dangerouslySetInnerHTML={{ __html: html }}></div> : html),
    },
    {
      title: 'Ajouter',
      key: 'action',
      render: (_, record) => <Button icon={<PlusOutlined />} type="primary" onClick={() => addNote(record)} />,
    },
  ]

  const deleteVolet = async () => {
    try {
      toast.dismiss()
      await toast.promise(
        api(`bilans/delete/${volet.id}/${prof.value}/${bilanId}`, {
          id: cookies['id'],
          token: cookies['token'],
        }),
        {
          pending: 'Envoi des données en cours...', // Message pendant le chargement
          success: 'Données supprimées avec succès 👌', // Message de succès
          error: {
            render({ data }) {
              // Retourne un message d'erreur basé sur la réponse de l'API
              return `Erreur lors de la suppression : ${data?.response?.data?.message || 'Veuillez réessayer.'}`
            },
          }, // Message d'erreur
        }
      )
      fetchDatas()
    } catch (error) {
      console.error('Erreur lors de la suppression des données :', error)
    }
  }
  const closeModalCommentaire = () => {
    setNouveauCommentaire({ prof: prof, volets: [volet] })
    setOpenModalCommentaire(false)
  }
  const saveCommentaire = async () => {
    try {
      toast.dismiss()
      await toast.promise(
        // Charger jeune
        apiPost(
          `bilans/editCommentBilan/not/${bilanId}`,
          {
            id: cookies['id'],
            token: cookies['token'],
          },
          { data: nouveauCommentaire }
        ),
        {
          pending: 'Envoi des données en cours...', // Message pendant le chargement
          success: 'Données sauvegardées avec succès 👌', // Message de succès
          error: {
            render({ data }) {
              // Retourne un message d'erreur basé sur la réponse de l'API
              return `Erreur lors de la sauvegarde : ${data?.response?.data?.message || 'Veuillez réessayer.'}`
            },
          }, // Message d'erreur
        }
      )
      fetchDatas()
      setOpenModalCommentaire(false)
      setTempNotesToBilan([])
    } catch (error) {
      console.error('Erreur lors de la récupération des données :', error)
    }
  }
  const filterNotes = (prof) => {
    const toutesLesNotes = [...notes] // Clone du tableau original pour éviter les mutations

    // Filtrer les notes dont le prof_id correspond à prof
    const notesFiltrees = toutesLesNotes.filter((note) => note.prof_id === prof)

    // Trier les notes filtrées par cours_id
    const notesTriees = notesFiltrees.sort((a, b) => {
      const coursIdA = a.cours_id[0] // Récupère la valeur unique dans le tableau cours_id
      const coursIdB = b.cours_id[0] // Même chose pour l'autre note
      return coursIdA - coursIdB // Comparaison croissante
    })
    setNotesFiltrees(notesTriees)
  }
  const filtrerNotesCours = (cours) => {
    const toutesLesNotes = [...notes] // Clone du tableau original pour éviter les mutations

    // Filtrer les notes dont le prof_id correspond à prof
    const notesFiltrees = toutesLesNotes.filter((note) => JSON.parse(note.cours_id)[0] === cours)

    setNotesFiltrees(notesFiltrees)
  }
  const debouncedUpdate = useCallback(
    debounce((value) => {
      setNouveauCommentaire((prevState) => {
        const updatedVolets = prevState.volets.map((volet) => ({
          ...volet,
          commentaire: value,
        }))
        return { ...prevState, volets: updatedVolets }
      })
    }, 500), // Débouncing après 500 ms
    []
  )
  const handleChange = (editor) => {
    const value = editor.getData()
    debouncedUpdate(value) // Appelle la fonction débouncée
  }
  const addNote = (ns) => {
    if (Array.isArray(ns)) {
      const lesNotes = [...modalNotes, ...ns]
      setModalNotes(lesNotes)
    } else {
      const lesNotes = [...modalNotes, ns]
      setModalNotes(lesNotes)
    }
    setTempNotesToBilan([])
  }
  function saveChartAsImage(chartRef) {
    if (chartRef.current) {
      html2canvas(chartRef.current).then((canvas) => {
        const imgData = canvas.toDataURL('image/png') // Convertit en Base64
        sendImageToApi(bilanId, imgData)
      })
    }
  }

  useEffect(() => {
    const comm = { ...nouveauCommentaire }
    comm.volets[0].notes = modalNotes
    setNouveauCommentaire(comm)
    if (modalNotes.length > 0) {
      setTempNotesToBilan([]) // Réinitialise chaque fois que modalNotes change
    }
  }, [modalNotes])
  useEffect(() => {
    setTempNotesToBilan([])
  }, [openModalCommentaire])
  useEffect(() => {
    if (cours.find((c) => c.value === volet.id).type === 4) {
      setIsAbsences(true)
      filtrerNotesCours(volet.id)
    } else {
      filterNotes(prof.value)
    }
  }, [])

  return (
    <div key={volet.id} id={`volet_${volet.id === 0 ? prof.value : cours.find((c) => c.value === volet.id).label}`}>
      <Drawer
        open={openModalCommentaire}
        placement="right"
        width="100%"
        title={'Editer un commentaire'}
        onClose={closeModalCommentaire}
        destroyOnClose={true}
        extra={
          <Button icon={<SaveOutlined />} type="primary" onClick={saveCommentaire}>
            Sauvegarder
          </Button>
        }
      >
        <Flex vertical gap={5}>
          <Flex justify="space-between">
            <Flex vertical style={{ width: '49%' }}>
              <Title level={5}>Quel prof ?</Title>
              <Select style={{ width: '100%' }} options={profs} value={nouveauCommentaire.prof} disabled />
            </Flex>
            <Flex vertical style={{ width: '49%' }}>
              <Title level={5}>Quel cours ?</Title>
              <Select style={{ width: '100%' }} options={cours} value={nouveauCommentaire.volets[0].id} disabled />
            </Flex>
          </Flex>
          <Splitter
            style={{
              marginTop: 10,
              marginBottom: 10,
              minHeight: 50,
              border: '1px solid #d9d9d9',
              borderRadius: 5,
            }}
          >
            <Splitter.Panel min="10%" max="90%">
              <Title style={{ paddingLeft: 10, paddingTop: 10 }} level={5}>
                Les notes du bilan
              </Title>
              <Flex vertical>
                {nouveauCommentaire.volets[0].notes.map((note, index) => (
                  <BilanNotesEdit
                    index={index}
                    key={note.id}
                    note={note}
                    nouveauCommentaire={nouveauCommentaire}
                    setNouveauCommentaire={setNouveauCommentaire}
                  />
                ))}
              </Flex>
            </Splitter.Panel>
            <Splitter.Panel>
              <Title style={{ paddingLeft: 10, paddingTop: 10 }} level={5}>
                Les notes du carnet
              </Title>
              <Flex vertical gap={10}>
                <Table
                  rowKey="id" // Utiliser un champ unique pour identifier chaque ligne
                  rowSelection={{
                    type: 'checkbox',
                    onChange: (selectedRowKeys, selectedRows) => {
                      setTempNotesToBilan(selectedRows)
                    },
                    getCheckboxProps: (record) => ({
                      disabled: record.disabled, // Désactiver certaines lignes si nécessaire
                    }),
                  }}
                  columns={addCommentsNotesColumns}
                  dataSource={notesFiltrees}
                  pagination={false}
                />
                <div style={{ padding: 10 }}>
                  <Button
                    disabled={!tempNotesToBilan.length > 0}
                    type="primary"
                    onClick={() => {
                      addNote(tempNotesToBilan)
                    }}
                  >
                    Ajouter {tempNotesToBilan.length} note{tempNotesToBilan.length > 1 ? 's' : ''} au bilan
                  </Button>
                </div>
              </Flex>
            </Splitter.Panel>
          </Splitter>
          {isAbsences ? (
            <Flex vertical>
              <Title style={{ paddingLeft: 10, paddingTop: 10 }} level={5}>
                Ajouter des diagrammes au bilan
              </Title>
              <Splitter
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  minHeight: 50,
                  maxHeight: 600,
                  border: '1px solid #d9d9d9',
                  borderRadius: 5,
                }}
              >
                <Splitter.Panel min="10%" max="90%">
                  <Flex wrap justify="center" align="center" gap={10} style={{ padding: 10 }}>
                    {charts.map((chart) => (
                      <Card
                        hoverable
                        style={{
                          paddingTop: 10,
                        }}
                        cover={
                          <img
                            style={{ width: 400, margin: 10 }}
                            src={`https://apiinterlude.compagnon.es/api/images/charts${chart}`}
                            key={chart}
                            alt="chart"
                          />
                        }
                        actions={[
                          <DeleteOutlined
                            key="delete"
                            onClick={() => {
                              deleteChart(chart)
                            }}
                          />,
                        ]}
                      />
                    ))}
                  </Flex>
                </Splitter.Panel>
                <Splitter.Panel>
                  <Flex vertical align="center">
                    <Segmented
                      style={{ margin: 10 }}
                      options={[
                        { label: '% globaux détaillés', value: 0, icon: <PieChartOutlined /> },
                        { label: '% globaux regroupés', value: 1, icon: <PieChartOutlined /> },
                        { label: 'Par jour', value: 2, icon: <BarChartOutlined /> },
                        { label: 'Par semaine', value: 3, icon: <LineChartOutlined /> },
                      ]}
                      value={chartSelected}
                      onChange={(value) => {
                        setChartSelected(value)
                      }}
                    />
                    {chartSelected === 0 ? (
                      <Flex vertical align="center">
                        <div ref={pieChartRef1}>
                          <PieChart width={400} height={220}>
                            <Legend verticalAlign="top" height={200} />
                            <Pie
                              data={absencesDatas.globalTypes}
                              dataKey="value"
                              cx="50%"
                              cy="50%"
                              outerRadius={100}
                              fill="#8884d8"
                              legendType="circle"
                              startAngle={0}
                              endAngle={180}
                              label
                              animationDuration={100}
                              animationBegin={0}
                            >
                              {absencesDatas.globalTypes.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} animationDuration={100} />
                              ))}
                            </Pie>
                          </PieChart>
                        </div>
                        <div>
                          <Button onClick={() => saveChartAsImage(pieChartRef1)}>
                            Ajouter le graphique au commentaire
                          </Button>
                        </div>
                      </Flex>
                    ) : chartSelected === 2 ? (
                      <Flex vertical align="center">
                        <div ref={barChartRef}>
                          <BarChart
                            width={500}
                            height={300}
                            data={absencesDatas.joursTypes}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <XAxis dataKey="name" />
                            <Legend />
                            {absencesDatas.globalTypes.map((type) => (
                              <Bar
                                key={type.name}
                                type="monotone"
                                dataKey={type.name}
                                fill={type.color}
                                animationDuration={100}
                              />
                            ))}
                          </BarChart>
                        </div>
                        <div>
                          <Button onClick={() => saveChartAsImage(barChartRef)}>
                            Ajouter le graphique au commentaire
                          </Button>
                        </div>
                      </Flex>
                    ) : chartSelected === 1 ? (
                      <Flex vertical align="center">
                        <div ref={pieChartRef2}>
                          <PieChart width={400} height={220}>
                            <Legend verticalAlign="top" height={200} />
                            <Pie
                              data={absencesDatas.globalGroupes}
                              dataKey="value"
                              cx="50%"
                              cy="50%"
                              outerRadius={100}
                              startAngle={0}
                              endAngle={180}
                              fill="#82ca9d"
                              label
                              animationBegin={0}
                              animationDuration={100}
                            >
                              {absencesDatas.globalGroupes.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} animationDuration={100} />
                              ))}
                            </Pie>
                          </PieChart>
                        </div>
                        <div>
                          <Button onClick={() => saveChartAsImage(pieChartRef2)}>
                            Ajouter le graphique au commentaire
                          </Button>
                        </div>
                      </Flex>
                    ) : chartSelected === 3 ? (
                      <Flex vertical align="center">
                        <div ref={lineChartRef}>
                          <LineChart
                            width={500}
                            height={300}
                            data={absencesDatas.semainesTypes}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {absencesDatas.globalTypes.map((type) => (
                              <Line
                                key={type.name}
                                type="monotone"
                                dataKey={type.name}
                                stroke={type.color}
                                animationDuration={100}
                              />
                            ))}
                          </LineChart>
                        </div>
                        <div>
                          <Button onClick={() => saveChartAsImage(lineChartRef)}>
                            Ajouter le graphique au commentaire
                          </Button>
                        </div>
                      </Flex>
                    ) : null}
                  </Flex>
                </Splitter.Panel>
              </Splitter>
            </Flex>
          ) : null}

          <h3 style={{ fontWeight: 600, fontSize: '1.17em' }}>Le commentaire</h3>
          <CKEditor
            ref={editorRef}
            editor={ClassicEditor}
            config={{
              toolbar: {
                items: [
                  'heading', // Titres
                  'fontSize', // Taille de texte
                  'bold', // Gras
                  'italic', // Italique
                  'underline', // Souligné
                  'strikethrough', // Barré

                  '|',
                  'bulletedList', // Liste non ordonnée (ul)
                  'numberedList', // Liste ordonnée (ol)
                  '|',
                  'alignment', // Alignement de texte
                  'link', // Liens

                  'undo', // Annuler
                  'redo', // Répéter
                ],
              },
              plugins: [Bold, Italic, Underline, Strikethrough, Font, Paragraph, List, Alignment, Link],
              fontSize: {
                options: ['tiny', 'small', 'default', 'big', 'huge'],
              },
              alignment: {
                options: ['left', 'center', 'right', 'justify'],
              },

              initialData: nouveauCommentaire.volets[0].commentaire,
            }}
            onChange={(e, editor) => {
              handleChange(editor)
            }}
          />
        </Flex>
      </Drawer>
      <Flex
        justify="space-between"
        style={{
          position: 'sticky',
          fontSize: 25,
          fontWeight: 600,
          top: 50, // Sticky en dessous du nom du professeur (ajustez selon la hauteur du nom du professeur)
          zIndex: 10, // En dessous du nom du professeur
          backgroundColor: 'white', // Semi-transparence pour éviter un chevauchement visuel
          marginBottom: 10,
          padding: 10,
          //borderBottom: '1px solid #ddd',
        }}
      >
        <Divider orientation="left" style={{ color: '#6a778e', fontSize: 20, borderColor: '#6a778e' }}>
          {volet.id === 0
            ? 'Commentaire global'
            : volet.id === 0
            ? prof.value
            : cours.find((c) => c.value === volet.id).label}
          {!fullScreen ? (
            <>
              <Tooltip title="Editer">
                <Button
                  shape="circle"
                  style={{ marginLeft: 10, color: '#6a778e', borderColor: '#6a778e' }}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setOpenModalCommentaire(true)
                    setTempNotesToBilan([])
                  }}
                />
              </Tooltip>
              <Popconfirm
                title="Supprimer ce cours"
                description="Es-tu sûr de supprimer ce cours?"
                onConfirm={deleteVolet}
                okText="Oui"
                cancelText="Non"
              >
                <Tooltip title="Supprimer">
                  <Button
                    shape="circle"
                    style={{ marginLeft: 10, color: '#6a778e', borderColor: '#6a778e' }}
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            </>
          ) : null}
        </Divider>
      </Flex>

      {/* Contenu du volet */}
      <div style={{ padding: 10 }}>
        {isAbsences ? (
          <Flex justify="space-around">
            {charts.map((chart) => (
              <Image
                width={charts.length > 2 ? 100 / charts.length + '%' : 100 / 3 + '%'}
                src={`https://apiinterlude.compagnon.es/api/images/charts${chart}`}
              />
            ))}
          </Flex>
        ) : null}
        <h3 style={{ fontWeight: 600, fontSize: '1.17em' }}>Les notes du carnet de liaison</h3>
        <Table
          style={{ marginTop: 10, marginBottom: 10 }}
          pagination={false}
          columns={notesColumns}
          dataSource={volet.notes}
        />
        <h3 style={{ fontWeight: 600, fontSize: '1.17em' }}>Le commentaire</h3>
        {typeof volet.commentaire === 'string' ? (
          <div
            style={{ marginTop: 10, marginBottom: 10 }}
            dangerouslySetInnerHTML={{ __html: volet.commentaire }}
          ></div>
        ) : (
          <div style={{ marginTop: 10, marginBottom: 10 }}>{volet.commentaire}</div>
        )}
      </div>
    </div>
  )
}

export default BilanVolet
