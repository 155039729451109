import { DeleteOutlined } from '@ant-design/icons'
import { Button, Card, Flex, Table } from 'antd'
import dayjs from 'dayjs'
import React, { useCallback, useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import 'ckeditor5/ckeditor5.css'
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  List,
  Underline,
  Strikethrough,
  Highlight,
  Alignment,
  Link,
  Font,
} from 'ckeditor5'
import debounce from 'lodash/debounce'

const BilanNotesEdit = ({ note, nouveauCommentaire, setNouveauCommentaire, index }) => {
  const deleteNote = (id) => {
    console.log(id)
    // Copie en profondeur de l'objet existant
    const updatedNouveauCommentaire = { ...nouveauCommentaire }

    // Mise à jour des notes dans chaque volet
    updatedNouveauCommentaire.volets = updatedNouveauCommentaire.volets.map((volet) => ({
      ...volet,
      notes: volet.notes.filter((n) => n.id !== id), // Supprime la note avec l'id donné
    }))

    // Mise à jour de l'état
    setNouveauCommentaire(updatedNouveauCommentaire)
  }

  const debouncedUpdate = useCallback(
    debounce((value) => {
      setNouveauCommentaire((prevState) => {
        const updatedVolets = prevState.volets.map((volet) => ({
          ...volet,
          notes: volet.notes.map((n) => (n.id === note.id ? { ...n, commentaire: value } : n)),
        }))
        return { ...prevState, volets: updatedVolets }
      })
    }, 500), // Débouncing après 500 ms
    []
  )

  // Fonction appelée à chaque modification de l'éditeur
  const handleChange = (editor) => {
    const value = editor.getData()
    debouncedUpdate(value) // Appelle la fonction débouncée
  }

  const newCommentsNotesColumns = [
    { title: 'Date', dataIndex: 'date', key: 'date', render: (date) => dayjs(date).format('DD/MM/YYYY') },
    {
      title: 'Commentaire',
      dataIndex: 'commentaire',
      key: 'commentaire',
      render: (record) => (
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: {
              items: [
                'heading', // Titres
                'fontSize', // Taille de texte
                'bold', // Gras
                'italic', // Italique
                'underline', // Souligné
                'strikethrough', // Barré

                '|',
                'bulletedList', // Liste non ordonnée (ul)
                'numberedList', // Liste ordonnée (ol)
                '|',
                'alignment', // Alignement de texte
                'link', // Liens
                'imageUpload', // Image
                'undo', // Annuler
                'redo', // Répéter
              ],
            },
            plugins: [Bold, Italic, Underline, Strikethrough, Font, Paragraph, List, Alignment, Link, Image],
            fontSize: {
              options: ['tiny', 'small', 'default', 'big', 'huge'],
            },
            alignment: {
              options: ['left', 'center', 'right', 'justify'],
            },

            initialData: note.commentaire,
          }}
          onChange={(e, editor) => {
            handleChange(editor)
          }}
        />
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => <Button icon={<DeleteOutlined />} type="primary" onClick={() => deleteNote(note.id)} />,
    },
  ]
  return <Table showHeader={index === 0} pagination={false} dataSource={[note]} columns={newCommentsNotesColumns} />
}

export default BilanNotesEdit
