import React from 'react'
import '../styles/AccesRefuse.scss'
import { StopOutlined } from '@ant-design/icons'
const AccesRefuse = () => {
  return (
    <div className="AccesRefuse">
      <StopOutlined style={{ fontSize: 50 }} />
      <h1>Accès refusé</h1>
    </div>
  )
}

export default AccesRefuse
