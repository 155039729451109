import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import Home from '../pages/Home.jsx'
import CarnetLisaisonLecture from '../pages/CarnetDeLiaisonLecture.jsx'
import Parametres from '../pages/Parametres.jsx'
import AccesRefuse from '../components/AccesRefuse.jsx'
import CarnetDeLiaisonEncodage from '../pages/CarnetDeLiaisonEncodage.jsx'
import EditeurDeTexte from '../pages/EditeurDeTexte.jsx'
import Jeune from '../pages/Jeune.jsx'
import NavBarJeune from './NavBarJeune.jsx'
import AbsencesEncodage from '../pages/AbsencesEncodage.jsx'
import MesInfos from '../pages/MesInfos.jsx'
import Bilan from '../pages/Bilan.jsx'
import { validateToken } from '../functions/api.js'

const Main = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['id', 'token'])
  const checkTokenValidity = async () => {
    const id = cookies.id
    const token = cookies.token

    if (!id || !token) {
      // Si les cookies sont absents, rediriger l'utilisateur vers la page de connexion
      console.warn("Pas de token ou d'ID trouvé.")
      removeCookie('id')
      removeCookie('token')
      removeCookie('cours_fav')
      return
    }
    const result = await validateToken(id, token)
    if (result.error) {
      // Token invalide : supprimer les cookies et rediriger vers la page de connexion
      console.warn('Token invalide. Suppression des cookies.')
      removeCookie('id')
      removeCookie('token')
      removeCookie('cours_fav')
    }
  }
  useEffect(() => {
    const intervalId = setInterval(async () => {
      await checkTokenValidity()
    }, 5 * 60 * 1000) // 5 minutes

    return () => clearInterval(intervalId) // Nettoyer l'intervalle lors du démontage
  }, [cookies, removeCookie])
  return (
    <div className="Main">
      {cookies['id'] && cookies['token'] ? (
        <Routes>
          <Route path="/" element={<AbsencesEncodage />} />
          <Route path="CarnetDeLiaisonLecture" element={<CarnetLisaisonLecture />} />
          <Route path="CarnetDeLiaisonEncodage" element={<CarnetDeLiaisonEncodage />} />
          <Route path="EditeurDeTexte" element={<EditeurDeTexte />} />
          <Route path="Jeune/:id/:page" element={<Jeune />} />
          <Route path="Parametres" element={<Parametres />} />
          <Route path="AbsencesEncodage" element={<AbsencesEncodage />} />
          <Route path="MesInfos" element={<MesInfos />} />
          <Route path="Bilan/:jeuneId/:bilanId" element={<Bilan />} />
        </Routes>
      ) : (
        <AccesRefuse />
      )}
    </div>
  )
}

export default Main
