import { Flex, Input, Popover, Radio, Space, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { apiEcole, apiPost } from '../functions/api'

const AjouterJeuneModal = ({ formData, setFormData }) => {
  const [cookies] = useCookies(['id', 'token', 'cours_fav'])

  const [titulaires, setTitulaires] = useState([])
  const [ecoles, setEcoles] = useState([])
  const fetchTitulaires = async () => {
    try {
      const response = await apiPost(`prof/getTitulaires`, {
        id: cookies['id'],
        token: cookies['token'],
      })
      setTitulaires(response.data)
    } catch (error) {
      console.error('Erreur lors de la récupération des profs:', error)
    }
  }
  const onSearch = async (e) => {
    const value = e.target.value
    if (value.length >= 3) {
      try {
        const response = await apiEcole('Secondaire', value)

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }
        const data = await response.json()

        //const records = data.records
        const nouvellesEcoles = data.results.map((ecole) => {
          return {
            value: `${ecole.nom_de_l_etablissement} (${ecole.commune_de_l_etablissement})`,
            label: `${ecole.nom_de_l_etablissement} (${ecole.commune_de_l_etablissement})`,
            key: ecole.ndegfase_de_l_etablissement,
          }
        })
        setEcoles((prevEcoles) => {
          // Ajouter les nouvelles écoles en évitant les doublons
          const ecolesMisesAJour = [...prevEcoles]
          nouvellesEcoles.forEach((nouvelleEcole) => {
            const existeDeja = ecolesMisesAJour.some((ecole) => ecole.value === nouvelleEcole.value)
            if (!existeDeja) {
              ecolesMisesAJour.push(nouvelleEcole)
            }
          })

          // Retirer les écoles dont le label ne contient plus searchValue
          return ecolesMisesAJour.filter((ecole) => ecole.label.toLowerCase().includes(value.toLowerCase()))
        })
      } catch (error) {
        console.error('Failed to fetch data:', error)
      }
    }
  }
  useEffect(() => {
    fetchTitulaires()
  }, [])
  return (
    <>
      <Flex justify={'space-between'} align={'center'} style={{ marginTop: 20 }}>
        <Space direction="vertical" style={{ width: '47%' }}>
          <Typography.Title
            style={{
              color: '#6a778e',
            }}
            level={5}
          >
            Le prénom
          </Typography.Title>
          <Input
            placeholder="Prénom"
            onChange={(e) => {
              setFormData((prevFormData) => ({
                ...prevFormData,
                prenom: e.target.value,
              }))
            }}
          />
        </Space>
        <Space direction="vertical" style={{ width: '47%' }}>
          <Typography.Title
            style={{
              color: '#6a778e',
            }}
            level={5}
          >
            Le nom de famille
          </Typography.Title>
          <Input
            placeholder="Nom de famille"
            onChange={(e) => {
              setFormData((prevFormData) => ({
                ...prevFormData,
                nom: e.target.value,
              }))
            }}
          />
        </Space>
      </Flex>

      <Flex justify={'space-between'} align={'top'} style={{ marginTop: 20 }}>
        <Space direction="vertical" style={{ width: '30%' }}>
          <Typography.Title
            style={{
              color: '#6a778e',
            }}
            level={5}
          >
            Le référent ou la référente
          </Typography.Title>
          <Radio.Group
            onChange={(e) => {
              setFormData((prevFormData) => ({
                ...prevFormData,
                referent: e.target.value,
              }))
            }}
          >
            <Space direction="vertical">
              {titulaires.map((titulaire, key) => (
                <Radio key={key} value={titulaire.id}>
                  {`${titulaire.nom} (${titulaire.tutores ? Object.keys(titulaire.tutores).length : 0}`}
                  <Popover
                    content={`${titulaire.tutores.map((tutore, key) => {
                      //console.log(tutore)
                      return (key > 0 ? ' ' : '') + tutore.nom
                    })}`}
                  >
                    <span>{`${Object.keys(titulaire.tutores).length > 1 ? ' jeunes' : ' jeune'})`}</span>
                  </Popover>
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Space>
        <Space direction="vertical" style={{ width: '69%' }}>
          <Typography.Title
            style={{
              color: '#6a778e',
            }}
            level={5}
          >
            L'école d'origine
          </Typography.Title>
          <Input placeholder="Ecole d'origine" onInput={onSearch} style={{ width: '100%' }} />
          <Radio.Group
            onChange={(e) => {
              setFormData((prevFormData) => ({
                ...prevFormData,
                ecole_origine: e.target.value,
              }))
            }}
          >
            <Space direction="vertical">
              {ecoles.map((ecole) => (
                <Radio key={ecole.value} value={ecole.value}>
                  {ecole.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Space>
      </Flex>
    </>
  )
}

export default AjouterJeuneModal
